import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
  CognitoRefreshToken,
} from 'amazon-cognito-identity-js';
import awsConfig from '../awsConfig';
import { jwtDecode } from 'jwt-decode';

interface Tokens {
  accessToken: string;
  idToken: string;
  refreshToken: string;
}

interface DecodedToken {
  exp: number;
  // Add other properties you expect from your JWT payload
}

const userPool = new CognitoUserPool({
  UserPoolId: awsConfig.UserPoolId,
  ClientId: awsConfig.ClientId,
});

export const authenticateUser = (
  username: string,
  password: string,
  newPassword?: string,
): Promise<string | 'NEW_PASSWORD_REQUIRED'> => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
  });

  const userData = {
    Username: username,
    Pool: userPool,
  };

  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session: CognitoUserSession) => {
        const tokens = {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(),
        };
        sessionStorage.setItem('cognitoTokens', JSON.stringify(tokens));

        resolve(session.getIdToken().getJwtToken());
      },
      onFailure: (err) => {
        reject(err.message || JSON.stringify(err));
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        if (newPassword) {
          delete userAttributes.email_verified;
          delete userAttributes.email;

          cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
            onSuccess: (session) => {
              const tokens = {
                accessToken: session.getAccessToken().getJwtToken(),
                idToken: session.getIdToken().getJwtToken(),
                refreshToken: session.getRefreshToken().getToken(),
              };
              sessionStorage.setItem('cognitoTokens', JSON.stringify(tokens));
              
              resolve(session.getIdToken().getJwtToken());
            },
            onFailure: (err) => {
              reject(err.message || JSON.stringify(err));
            },
          });
        } else {
          resolve('NEW_PASSWORD_REQUIRED');
        }
      },
    });
  });
};

export const isAuthenticated = () => {
  const tokensStr = sessionStorage.getItem('cognitoTokens');
  if (!tokensStr) return false;
  
  const { idToken, accessToken } = JSON.parse(tokensStr);
  return !(isTokenExpired(idToken) || isTokenExpired(accessToken));
};

export const logout = () => {
  const cognitoUser = userPool.getCurrentUser();
  if (cognitoUser) {
    cognitoUser.signOut();
  }
  sessionStorage.removeItem('cognitoTokens');
};

export const getAuthTokens = () => {
  const tokensStr = sessionStorage.getItem('cognitoTokens');
  return tokensStr ? JSON.parse(tokensStr) : null;
};

const saveTokens = (tokens: Tokens) => {
  sessionStorage.setItem('cognitoTokens', JSON.stringify(tokens));
};

const clearTokens = () => {
  sessionStorage.removeItem('cognitoTokens');
};

// Decode JWT token and check expiration
const isTokenExpired = (token: string): boolean => {
  const decoded: DecodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // UNIX epoch in seconds
  return decoded.exp < currentTime;
};

export const initiateTokenRefresh = async (username: string) => {
  const tokens = getAuthTokens();
  if (!tokens || !tokens.refreshToken) throw new Error("No refresh token available");

  if (isTokenExpired(tokens.accessToken)) {
    try {
      await refreshTokens(tokens.refreshToken, username);
    } catch (error) {
      console.error("Error refreshing tokens:", error);
      clearTokens(); // Optional: force logout or re-authentication as a fallback
      throw error;
    }
  }
};

const refreshTokens = (refreshToken: string, username: string) => {
  const userData = { Username: username, Pool: userPool };
  const cognitoUser = new CognitoUser(userData);

  return new Promise((resolve, reject) => {
    cognitoUser.refreshSession(new CognitoRefreshToken({ RefreshToken: refreshToken }), (err, session) => {
      if (err) {
        reject(err);
      } else {
        const tokens = {
          accessToken: session.getAccessToken().getJwtToken(),
          idToken: session.getIdToken().getJwtToken(),
          refreshToken: session.getRefreshToken().getToken(), // You may choose to not update the refresh token
        };
        saveTokens(tokens);
        resolve(tokens);
      }
    });
  });
};

export const refreshSession = async (): Promise<void> => {
  const userPoolData = {
    UserPoolId: awsConfig.UserPoolId,
    ClientId: awsConfig.ClientId,
  };
  const userPool = new CognitoUserPool(userPoolData);
  const cognitoUser = userPool.getCurrentUser();

  if (!cognitoUser) {
    throw new Error('User not found.');
  }

  return new Promise((resolve, reject) => {
    cognitoUser.getSession((err: Error | null, session: CognitoUserSession) => {
      if (err || !session.isValid()) {
        reject(new Error('Failed to get valid session.'));
        return;
      }

      const refreshToken = session.getRefreshToken();
      if (!refreshToken.getToken()) {
        reject(new Error('Refresh token missing.'));
        return;
      }

      cognitoUser.refreshSession(refreshToken, (refreshError, refreshSession) => {
        if (refreshError) {
          reject(refreshError);
        } else {
          const tokens = {
            accessToken: refreshSession.getAccessToken().getJwtToken(),
            idToken: refreshSession.getIdToken().getJwtToken(),
            refreshToken: refreshSession.getRefreshToken().getToken(),
          };
          sessionStorage.setItem('cognitoTokens', JSON.stringify(tokens));
          resolve();
        }
      });
    });
  });
};

export const decodeAndSetUser = (idToken: string, setUser: Function) => {
  const decoded: any = jwtDecode(idToken); // Adjust the typing based on your token's structure
  setUser({
    isAuthenticated: true,
    userData: {
      token: idToken,
      username: decoded.username, // Make sure this aligns with your token's structure
    },
  });
};
