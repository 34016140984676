// src/state/userState.ts
import { atom } from 'recoil';

interface UserDataType {
  token: string;
  username?: string;
}

interface UserStateType {
  isAuthenticated: boolean;
  userData: UserDataType | null;
}

export const userState = atom<UserStateType>({
  key: 'userState',
  default: { isAuthenticated: false, userData: null },
});
