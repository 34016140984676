// src/routes.ts
import { lazy } from 'react';

// Lazy loading components for better performance
const LoginPage = lazy(() => import('./screens/LoginPage'));
const ForgetPasswordPage = lazy(() => import('./screens/ForgetPasswordPage'));
const JobList = lazy(() => import('./screens/jobs/JobList'));
const ResourcesList = lazy(() => import('./screens/ResourceList'));
const CreateJob = lazy(() => import('./screens/jobs/CreateJob'));
const JobView = lazy(() => import('./screens/jobs/JobView'));

export const routeConfig = [
  {
    path: '/',
    element: LoginPage,
    protected: false,
    menu: false
  },
  {
    path: '/forget-password',
    element: ForgetPasswordPage,
    protected: false,
    menu: false
  },
  // Adding the new routes for the admin sections
  {
    path: '/jobs',
    element: JobList,
    protected: true,
    menu: true
  },
  {
    path: '/jobs/create',
    element: CreateJob,
    protected: true,
    menu: false
  },
  {
    path: '/jobs/:jobId',
    element: JobView,
    protected: true,
    menu: false
  },
  {
    path: '/resources',
    element: ResourcesList,
    protected: true,
    menu: true
  },
  // More routes can be added here
];
