import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AuthInitializer from './components/AuthInitializer';
import { routeConfig } from './routes'; // Make sure this path is correct
import ProtectedLayout from './layout/ProtectedLayout';

const App: React.FC = () => {
  return (
    <RecoilRoot>
      <Router>
        <AuthInitializer>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              {routeConfig.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    route.protected ? (
                      <ProtectedLayout>
                        <route.element />
                      </ProtectedLayout>
                    ) : (
                      <route.element />
                    )
                  }
                />
              ))}
            </Routes>
          </Suspense>
        </AuthInitializer>
      </Router>
    </RecoilRoot>
  );
};

export default App;
