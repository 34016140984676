import React, { useState } from 'react';
import { Layout, Menu, Dropdown, Avatar } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { routeConfig } from '../routes';
import { DesktopOutlined, PieChartOutlined, FileOutlined, TeamOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useRecoilState } from 'recoil';
import { userState } from '../state/userState';
import { logout } from '../utils/auth';
import { Engineering, People, SettingsOutlined, Work } from '@mui/icons-material';

const { Header, Content, Footer, Sider } = Layout;

const ProtectedLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useRecoilState(userState);
  const [collapsed, setCollapsed] = useState(true);

  const handleSignOut = () => {
    logout();
    setUser({ isAuthenticated: false, userData: null });
    navigate('/');
  };

  const accountMenu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleSignOut}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  const headerStyle: React.CSSProperties = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 20px',
    background: '#fff',
  };

  const menuItems = routeConfig.filter(route => route.protected && route.menu).map((route, index) => ({
    key: route.path,
    icon: route.path.includes('jobs') ? <Engineering /> :
          route.path.includes('resources') ? <People /> :
          route.path.includes('rides') ? <FileOutlined /> :
          <TeamOutlined />,
    label: route.path.replace('/', '').charAt(0).toUpperCase() + route.path.slice(2),
    onClick: () => navigate(route.path),
  }));

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        <div className="logo" />
        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]} items={menuItems} />
      </Sider>
      <Layout>
        <Header style={headerStyle}>
          <div />
          {user.isAuthenticated && (
            <Dropdown overlay={accountMenu} placement="bottomRight">
              <Avatar style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            </Dropdown>
          )}
        </Header>
        <Content style={{ margin: '24px 8px', padding: 8, overflow: 'hidden' }}>
          {children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          RCS ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
};

export default ProtectedLayout;
