// src/components/AuthInitializer.tsx
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { userState } from '../state/userState';
import { getAuthTokens, isAuthenticated, logout, refreshSession } from '../utils/auth';
import { useNavigate } from 'react-router-dom'; // Import if navigation based on auth status is needed

const AuthInitializer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const setUser = useSetRecoilState(userState);
  const navigate = useNavigate(); // Use navigate to redirect user if needed

  useEffect(() => {
    const initializeAuth = async () => {
      if (!isAuthenticated()) {
        try {
          await refreshSession(); // Attempt to refresh session if needed
          const refreshedTokens = getAuthTokens();
          if (refreshedTokens && refreshedTokens.idToken) {
            setUser({ isAuthenticated: true, userData: { token: refreshedTokens.idToken } });
            navigate('/jobs');
          } else {
            // Optional: Redirect to login or show message if not authenticated
            navigate("/"); // Adjust route as needed
          }
        } catch (error) {
          // console.error("Failed to refresh tokens:", error);
          logout(); // Clear the session
          setUser({ isAuthenticated: false, userData: null }); // Update the global state
          navigate("/"); // Adjust route as needed
        }
      } else {
        // If already authenticated (i.e., valid tokens are present), set user state directly
        const tokens = getAuthTokens();
        if (tokens && tokens.idToken) {
          setUser({ isAuthenticated: true, userData: { token: tokens.idToken } });
          // navigate('/dashboard');
        } else {
          navigate("/"); // Adjust route as needed
        }
      }
    };

    initializeAuth();
  }, [setUser, navigate]);

  // You can add any loader or splash screen here if you want to show something while checking the auth state
  return <>{children}</>;
};

export default AuthInitializer;
